import { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";

function GoToTopButton() {
  const [goTopButton, setGoTopButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (window.scrollY > 300) {
        setGoTopButton(true);
      } else {
        setGoTopButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function GoToTop() {
    if (goTopButton) {
      return (
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={<Tooltip id="tooltip-top">Go To Top</Tooltip>}
        >
          <button
            type="button"
            className="bg-warning text-white btn rounded-circle fs-3"
            onClick={() => scrollToTop()}
          >
            <i className="bi bi-arrow-up-circle"></i>
          </button>
        </OverlayTrigger>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="fixed-bottom text-white text-end me-3 mb-3">
      <div className="row gap-2">
        <div className="col-12">
          <GoToTop />
        </div>
        <div className="col-12">
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={<Tooltip id="tooltip-top">Whatsapp</Tooltip>}
          >
            <Link
              type="button"
              style={{ backgroundColor: "#30d14e" }}
              className="text-white btn rounded-circle fs-3"
              to={"https://wa.me/+917042562687"}
              target="_blank"
            >
              <i className="bi bi-whatsapp"></i>
            </Link>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}

export default GoToTopButton;
