import { RefObject } from "react";
import AppImg from "../../../../assets/app.webp";
function AdPageHome({
  aboutAppDivRef,
  appProjectsDivRef,
  scrollHandler,
}: {
  aboutAppDivRef: RefObject<HTMLDivElement>;
  appProjectsDivRef: RefObject<HTMLDivElement>;
  scrollHandler: (elementRef: RefObject<HTMLDivElement>) => void;
}) {
  return (
    <section id="app-home" className="pb-md-5">
      <div className="container py-5 ">
        <div className="row align-items-center">
          <div className="col-md-6 mb-3">
            <h3 className="text-center text-light">
              <b>
                Accelerate Your Success with <br className="break" />
                VyomsCode App Devlopment Solutions
              </b>
            </h3>
            <p className="text-light text-center">
              Unlock Efficiency and Innovation with VyomsCode's Automated App
              Development Solutions
            </p>
            <div className="d-flex justify-content-center">
              <span
                className="nav-link"
                onClick={() => scrollHandler(aboutAppDivRef)}
              >
                <button className="btn btn-outline-light  mx-1">
                  App Services
                </button>
              </span>
              <span
                className="nav-link"
                onClick={() => scrollHandler(appProjectsDivRef)}
              >
                <button className="btn btn-outline-light  mx-1">
                  App Projects
                </button>
              </span>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center mt-3">
            <img src={AppImg} className="w-100" alt="App Devlopment" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdPageHome;
