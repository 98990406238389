import { RefObject, useRef } from "react";
import GdAboutGraphic from "./graphic-designing-subcomponents/GdAboutGraphic";
import GdGraphicContact from "./graphic-designing-subcomponents/GdGraphicContact";
import GdGraphicProjects from "./graphic-designing-subcomponents/GdGraphicProjects";
import GdPageHome from "./graphic-designing-subcomponents/GdPageHome";
import {Helmet} from "react-helmet";

function GraphicDesigning() {
  const aboutGraphicDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ backgroundColor: "rgb(184, 216, 226)" }}>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Vyomscode Graphic Design | Branding & Creative Visuals</title>
          <meta
            name="description"
            content="Vyomscode offers professional graphic design services. Get unique branding, logos, and visuals that set your business apart"
          />
          <meta 
          name="keywords" 
          content="graphic design India, logo banwane ka tarika, brand identity kaise banaye, creative design services, Vyomscode graphics, design mein branding kaise ho, unique branding solutions, logo kaise banwaye, visuals for business, design agency India, business ka brand identity, eye-catching branding, impactful visuals, stunning illustrations, brand identity design, marketing collaterals, graphic design services, logo design, illustration, branding design, Delhi graphic design agency, Mumbai design company, Varanasi design studio, Lucknow design solutions" />
      </Helmet>
      <GdPageHome
        aboutGraphicDivRef={aboutGraphicDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="aboutGraphicDiv" ref={aboutGraphicDivRef}>
        <GdAboutGraphic />
      </div>

      <GdGraphicProjects />

      <GdGraphicContact />
    </div>
  );
}

export default GraphicDesigning;
