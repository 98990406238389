import { RefObject } from "react";

function PricingPageHome({
  webPricingDivRef,
  digitalPricingDivRef,
  graphicPricingDivRef,
  appPricingDivRef,
  scrollHandler,
}: {
  webPricingDivRef: RefObject<HTMLDivElement>;
  digitalPricingDivRef: RefObject<HTMLDivElement>;
  graphicPricingDivRef: RefObject<HTMLDivElement>;
  appPricingDivRef: RefObject<HTMLDivElement>;
  scrollHandler: (elementRef: RefObject<HTMLDivElement>) => void;
}) {
  return (
    <section id="pricing-home">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>Our Pricing Plan</b>
            </h3>
            <p className="text-light text-center">
              Flexible Pricing Structure: Rates Subject to Project Size and
              Completion Time
            </p>
            <div className="d-flex text-center justify-content-center">
              <span
                className="nav-link"
                onClick={() => {
                  scrollHandler(graphicPricingDivRef);
                }}
              >
                <button className="btn btn-outline-light  m-1">
                  Graphic Design
                </button>
              </span>
              <span
                className="nav-link"
                onClick={() => {
                  scrollHandler(appPricingDivRef);
                }}
              >
                <button className="btn btn-outline-light  m-1">
                  App Devlopment
                </button>
              </span>
            </div>
            <div className="d-flex justify-content-center  my-md-0">
              <span
                className="nav-link"
                onClick={() => {
                  scrollHandler(webPricingDivRef);
                }}
              >
                <button className="btn btn-outline-light  m-1">
                  Website Devlopment
                </button>
              </span>
              <span
                className="nav-link"
                onClick={() => {
                  scrollHandler(digitalPricingDivRef);
                }}
              >
                <button className="btn btn-outline-light  m-1">
                  Digital Marketing
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingPageHome;
