import GifImg  from "../../../assets/homegif.gif"; 

function MpShowCase() {
  return (
    <section id="show-case">
      <div className="container d-flex justify-content-center"> 
        <img src={GifImg} alt="" className="GifHomeImg" />
      </div>
    </section>
  );
}

export default MpShowCase;
