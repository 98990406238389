import { Link } from "react-router-dom";

function PricingApp() {
  return (
    <section id="pricing-app" className="pricing">
      <div className="container  pb-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-4">
            <h3 className="text-center text-light">
              <b>App Devlopment</b>
            </h3>
            <p className="text-center text-light "></p>
            <h6 className="text-center">
              <Link to="/services/app-development" className="">
                <button className="btn btn-warning">Explore Services</button>
              </Link>
            </h6>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <h5 className="text-center text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 74999/
                </h5>
              </div>
              <div className="card-body">
                <p className="text-center text-light">
                  Requirement Analysis <br />
                  Platform Selection
                  <br />
                  UI/UX Design
                  <br />
                  Native or Cross-Platform Development
                  <br />
                  Backend Development
                  <br />
                  Frontend Development
                  <br />
                  Integration of Features
                  <br />
                  Testing and Quality Assurance
                  <br />
                  App Store Optimization (ASO)
                  <br />
                  Security Measures
                  <br />
                  App Maintenance and Support
                  <br />
                  Scalability
                  <br />
                  Offline Functionality
                  <br />
                  Analytics and Performance Monitoring
                  <br />
                  Compliance and Regulation
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingApp;
