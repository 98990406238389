import { testimonialData } from "../../../common/data";
import TestimonialCarousel from "../reusable-components/TestimonialCarousel";

function MpTestimonials() {
  return (
    <section className="testimonials">
      <div className="container p-md-5 py-5">
        <h2 className="text-center text-white mb-4">What Our Clients Say</h2>
        <div
          id="carouselExampleIntervalTestimonial"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {testimonialData.map((item) => {
              return (
                <TestimonialCarousel
                  key={item.id}
                  id={item.id}
                  testimonialText={item.testimonialText}
                  personName={item.personName}
                  personCompany={item.personCompany}
                  personTitle={item.personTitle}
                  imgSrc={item.imgSrc}
                />
              );
            })}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIntervalTestimonial"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIntervalTestimonial"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default MpTestimonials;
