// import Flex1Img from "../../../../assets/project-img/flex1png.png";
import Flex2Img from "../../../../assets/project-img/flex2png.png";
import Flex3Img from "../../../../assets/project-img/flex3png.png";

import { GraphicDesignData, GraphicUIUXData } from "../../../../common/data";
import GraphicDesignSocialMediaCard from "../../../reusables/GraphicDesignSocialMediaCard";
import GraphicDesignUiUxCard from "../../../reusables/GraphicDesignUiUxCard";

function GdGraphicProjects() {
  return (
    <section id="graphic-projects">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 ">
            <h3 className="text-center heading-graphic">
              GRAPHIC DESIGN PROJECTS
            </h3>
            <p className="text-center  mb-4">
              <b>
                With expertise across various graphic design tools, we've
                successfully delivered diverse projects tailored to various
                industries. From Canva, Inkscape, to Figma, we're equipped to
                bring your vision to life.
              </b>
            </p>
          </div>

          {GraphicDesignData.map((item) => {
            return (
              <GraphicDesignSocialMediaCard
                key={item.id}
                graphicTitle={item.graphicTitle}
                graphicImage={item.graphicImage}
                graphicImageAlt={item.graphicImageAlt}
              />
            );
          })}

          <div className="col-md-12 mb-1"></div>

          {GraphicUIUXData.map((item) => {
            return (
              <GraphicDesignUiUxCard
                key={item.id}
                graphicTitle={item.graphicTitle}
                graphicImage={item.graphicImage}
                graphicImageAlt={item.graphicImageAlt}
              />
            );
          })}

          <div className="col-md-12 mb-1"></div>
 
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-yellow">
              <div className="card-head">
                <img
                    src={Flex2Img}
                  className="card-img-top rounded-top"
                  alt="Flex Board design for school (our client)"
                />
              </div>
              <div className="card-body">
                <p className="text-center mb-0 text-light">
                  Flex Board design for school (our client)
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-yellow">
              <div className="card-head">
                <img
                    src={Flex3Img}
                  className="card-img-top rounded-top"
                  alt="Flex Board design for school (our client)"
                />
              </div>
              <div className="card-body">
                <p className="text-center mb-0 text-light">
                  Flex Board design for school (our client)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GdGraphicProjects;
