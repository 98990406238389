import { RefObject, useRef } from "react";
import AdAboutApp from "./app-development-subcomponents/AdAboutApp";
import AdAppProjects from "./app-development-subcomponents/AdAppProjects";
import AdPageHome from "./app-development-subcomponents/AdPageHome";
import {Helmet} from "react-helmet";

function AppDevelopment() {
  const aboutAppDivRef = useRef<HTMLDivElement>(null);
  const appProjectsDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Vyomscode App Development | High-Quality Mobile App Solutions</title>
          <meta
            name="description"
            content="Vyomscode specializes in custom mobile apps for iOS and Android. Create a powerful, user-friendly app for your business goals"
          />
          <meta 
          name="keywords" 
          content="mobile app kaise banwaye, app development services, iOS and Android apps, mobile app banane ka kharcha, Android app banwaye, iOS app development, custom app banaye, app development India, business app kaise banaye, Vyomscode app development, mobile ka app kaise banwaye, mobile app solutions, native iOS/Android apps, cross-platform development, intuitive UX, feature-rich apps, scalable architectures,  mobile app development, iOS app development, Android app dev, Delhi app agency, Mumbai app development company, Varanasi app services, Lucknow app solutions" />
      </Helmet>
      <AdPageHome
        aboutAppDivRef={aboutAppDivRef}
        appProjectsDivRef={appProjectsDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="aboutAppDivRef" ref={aboutAppDivRef}>
        <AdAboutApp />
      </div>

      <div id="appProjectsDiv" ref={appProjectsDivRef}>
        <AdAppProjects />
      </div>
    </div>
  );
}

export default AppDevelopment;
