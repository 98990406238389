import PathImg from '../../../assets/pathPng.png'

function MpHowItWorks() {
  return (
    <section id="how-it-works">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 my-2">
            <h3 className="text-center text-light">How Vyomscode Works</h3>
            <p className="text-center text-light">
              At Vyomscode, we aim to streamline the process of web development
              and app development, empowering businesses and individuals to
              bring their ideas to life efficiently and effectively. Our
              platform offers a seamless experience, from conceptualization to
              deployment, with a focus on simplicity, collaboration, and
              innovation.
            </p>
          </div>
          <div className="col-12 d-flex justify-content-center mb-3">
            <img
              src={PathImg}
              alt="Vyomscode Work path"
              className="path-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 scrolling-div">
            <div className=" col-4 px-1 ">
              <h3 className="text-center">
                <button className="btn btn-warning rounded-circle">1</button>
              </h3>
              <h6 className="text-center">
                <b> Project Initiation</b>
              </h6>
              <p className="mb-0 text-center">
                Submit project requirements - consult experts for alignment with
                goals and vision, ensuring precise understanding.
              </p>
            </div>

            <div className=" col-4 px-1 ">
              <h3 className="text-center">
                <button className="btn btn-danger rounded-circle">2</button>
              </h3>
              <h6 className="text-center">
                <b> Development Phase</b>
              </h6>
              <p className="mb-0 text-center">
                Adopt agile development methodology - collaborate in real-time,
                tracking progress seamlessly for efficient project management..
              </p>
            </div>

            <div className=" col-4 px-1 ">
              <h3 className="text-center">
                <button className="btn btn-success rounded-circle">3</button>
              </h3>
              <h6 className="text-center">
                <b> Customization & Integration</b>
              </h6>
              <p className="mb-0 text-center">
                Receive tailored solutions leveraging cutting-edge technologies
                - seamlessly integrate third-party tools for enhanced
                functionality.
              </p>
            </div>

            <div className=" col-4 px-1 ">
              <h3 className="text-center">
                <button className="btn btn-primary rounded-circle">4</button>
              </h3>
              <h6 className="text-center">
                <b> Quality Assurance</b>
              </h6>
              <p className="mb-0 text-center">
                Implement rigorous testing procedures - promptly address
                identified issues and optimize for reliability and usability.
              </p>
            </div>

            <div className=" col-4 px-1 ">
              <h3 className="text-center">
                <button
                  className="btn rounded-circle text-light"
                  style={{ backgroundColor: "purple" }}
                >
                  5
                </button>
              </h3>
              <h6 className="text-center">
                <b> Deployment and Support</b>
              </h6>
              <p className="mb-0 text-center">
                Facilitate smooth deployment processes - provide ongoing
                support, maintenance, and updates for long-term success.
              </p>
            </div>

            <div className=" col-4 px-1 ">
              <h3 className="text-center">
                <button
                  className="btn rounded-circle"
                  style={{ backgroundColor: "aqua" }}
                >
                  6
                </button>
              </h3>
              <h6 className="text-center">
                <b> Continuous Improvement</b>
              </h6>
              <p className="mb-0 text-center">
                Value client feedback for continuous improvement - stay updated
                with latest trends and innovations in the industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MpHowItWorks;
