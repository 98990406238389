import { RefObject } from "react";
import LaptopPng from "../../../../assets/laptop-png-laptop-png-image-6754-4042.webp";
import UntitledGif from "../../../../assets/Untitled design (4).gif";

function WdWebHome({
  webServicesDivRef,
  webProjectsDivRef,
  scrollHandler,
}: {
  webServicesDivRef: RefObject<HTMLDivElement>;

  webProjectsDivRef: RefObject<HTMLDivElement>;

  scrollHandler: (elementRef: RefObject<HTMLDivElement>) => void;
}) {
  return (
    <section id="web-home">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>
                Automate Your Way to success with <br className="break" />{" "}
                VyomsCode Website Devlopment Solutions{" "}
              </b>
            </h3>
            <p className="text-light text-center">
              Empower Your Growth Journey with VyomsCode's Automated Web
              Solutions
            </p>
            <div className="d-flex justify-content-center">
              <span
                className="nav-link"
                onClick={() => scrollHandler(webServicesDivRef)}
              >
                <button className="btn btn-outline-light  mx-1">
                  Web Services
                </button>
              </span>
              <span
                className="nav-link"
                onClick={() => scrollHandler(webProjectsDivRef)}
              >
                <button className="btn btn-outline-light  mx-1">
                  Web Projects
                </button>
              </span>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <img
              src={LaptopPng}
              className="laptop-img"
              alt="Laptop with background transparent"
            />
            <img src={UntitledGif} className="gif-img" alt="" />
          </div>
        </div>
        <div className="row d-flex justify-content-center"></div>
      </div>
    </section>
  );
}

export default WdWebHome;
