import { Link } from "react-router-dom";

function PricingWeb() {
  return (
    <section id="pricing-web" className="pricing">
      <div className="container pb-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h3 className="text-center text-light">
              <b>Website Devlopment</b>
            </h3>
            <p className="text-center text-light">
              Contact us to know about pricing plan which include React Js,
              Angular Js, Laravel frameworks.
            </p>
            <h6 className="text-center">
              <Link to="/services/website-development" className="">
                <button className="btn btn-warning">Explore Services</button>
              </Link>
            </h6>
          </div>
          <div className="card card-body">
            <div className="row">
              {/* <div className="col-md-3 border py-2">
                <h5 className="text-light">SMALL & BASIC WEBSITE</h5>
                <h5 className="text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 8999/
                </h5>
                <p className="text-light">
                  Choose From One Of Our Many DESIGNS <br />
                  Affordable Website Solution <br />
                  4 Page Static Website
                  <br />
                  Custom Design <br />
                  Installation & Setup <br />
                  Customized Home Page <br />
                  Photo Gallery <br />
                  Newsletter Signup Form <br />
                  Contact form <br />
                  Mobile and tablet friendly design <br />
                  Social media integration <br />
                  Full Customer Support for Website <br />
                </p>
                <br />
                <br />
                <h6>
                  <Link to="https://wa.me/+917042562687">
                    <button className="btn btn-warning btn-sm">
                      Conatct Us Now
                    </button>
                  </Link>
                </h6>
              </div> */}

              <div className="col-md-4 border py-2">
                <h5 className="text-light">CUSTOM TAILORED WEBSITE</h5>
                <h5 className=" text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 30,000/
                </h5>
                <p className="text-light">
                  Meet Your Online Demands With A Custom Website
                  <br />
                  Affordable Website Solution <br />
                  10 To 15 Pages
                  <br />
                  Responsive Design
                  <br />
                  Installation & Setup <br />
                  Customized Home Page <br />
                  Photo Gallery <br />
                  Newsletter Signup Form <br />
                  Contact form <br />
                  Mobile and tablet friendly design <br />
                  Social media integration <br />
                  Full Customer Support for Website <br />
                  Search Engine Optimized
                </p>
                <br />
                <h6>
                  <Link to="https://wa.me/+917042562687">
                    <button className="btn btn-warning btn-sm">
                      Conatct Us Now
                    </button>
                  </Link>
                </h6>
              </div>
              <div className="col-md-4 border py-2">
                <h5 className="text-light">POWERFUL WORDPRESS WEBSITE</h5>
                <h5 className=" text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 45999/
                </h5>
                <p className="text-light">
                  For Businesses Looking For Online Functionality
                  <br />
                  20-25 Pages
                  <br />
                  SEO Superior Website
                  <br />
                  Admin Panel W/ Login
                  <br />
                  Installation & Setup <br />
                  Customized Home Page <br />
                  Photo Gallery <br />
                  Newsletter Signup Form <br />
                  Contact form <br />
                  Mobile and tablet friendly design <br />
                  Social media integration <br />
                  Full Customer Support for Website <br />
                </p>
                <h6>
                  <Link to="https://wa.me/+917042562687">
                    <button className="btn btn-warning btn-sm">
                      Conatct Us Now
                    </button>
                  </Link>
                </h6>
              </div>
              <div className="col-md-4 border py-2">
                <h5 className="text-light">ONLINE ECOMMERCE WEBSITE</h5>
                <h5 className=" text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 75999/
                </h5>
                <p className="text-light">
                  Designed To Sell Products For Any Kind Of Industry
                  <br />
                  Unlimited Products & Pages
                  <br />
                  Sell Products Online
                  <br />
                  Powerful Platform
                  <br />
                  Custom Design
                  <br />
                  Installation & Setup <br />
                  Customized Home Page <br />
                  Photo Gallery <br />
                  Newsletter Signup Form <br />
                  Contact form <br />
                  Mobile and tablet friendly design <br />
                  Social media integration <br />
                  Full Customer Support for Website <br />
                </p>
                <h6>
                  <Link target="_blank" to="https://wa.me/+917042562687">
                    <button className="btn btn-warning btn-sm">
                      Conatct Us Now
                    </button>
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingWeb;
