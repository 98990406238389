import { RefObject, useRef } from "react";
import WdAboutWeb from "./website-development-subcomponents/WdAboutWeb";
import WdWebHome from "./website-development-subcomponents/WdWebHome";
import WdWebProjects from "./website-development-subcomponents/WdWebProjects";
import {Helmet} from "react-helmet";

function WebsiteDevelopment() {
  const webServicesDivRef = useRef<HTMLDivElement>(null);
  const webProjectsDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Vyomscode Web Development | Custom Websites & E-commerce Solutions</title>
          <meta
            name="description"
            content="Get a custom-built website from Vyomscode. We provide responsive, SEO-optimized websites and e-commerce solutions for businesses in India. "
          />
          <meta 
          name="keywords" 
          content="website kaise banwaye, custom website kaise banwaye, e-commerce solutions, best website builders, website design India, SEO wala website banwaye, business website banaye, website development India, website banwane ki services, e-commerce platform development, Vyomscode website service, website ka design aur development, responsive web design, user-friendly interfaces, search engine optimized, e-commerce solutions, custom web applications, web development services, custom website design, web application development, Delhi web design agency, Mumbai web dev company, Varanasi web services, Lucknow web solutions" />
      </Helmet>
      <WdWebHome
        webServicesDivRef={webServicesDivRef}
        webProjectsDivRef={webProjectsDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="webServicesDiv" ref={webServicesDivRef}>
        <WdAboutWeb />
      </div>

      <div id="webProjectsDiv" ref={webProjectsDivRef}>
        <WdWebProjects />
      </div>
    </div>
  );
}

export default WebsiteDevelopment;
