import BlogCard from "../reusable-components/BlogCard";
import { BlogDataApi } from "../../../api/ApiCalls";
import { useEffect, useState } from "react";
import { BlogDataType } from "../../../common/dataType";

function MpBlog() {
  const [blogData, setBlogData] = useState<BlogDataType[]>([]);

  async function getBlogData() {
    const result = await BlogDataApi();

    const parsedResult = JSON.parse(result);

    return parsedResult;
  }

  useEffect(() => {
    getBlogData().then((data) => setBlogData(data));
  }, []);

  return (
    <section id="blog">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 my-2 mb-4">
            <h3 className="text-center text-light">
              <b>Our Recent Blog</b>
            </h3>
          </div>

          {blogData.map((item, index) => {
            return (
              <BlogCard
                key={index}
                blogLink={item.blog_link}
                blogTitle={item.blog_title}
                blogImage={item.img_path}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default MpBlog;
