import { TestimonialDataType } from "../../../common/dataType";

function TestimonialCarousel({
  id,
  personName,
  personCompany,
  personTitle,
  testimonialText,
  imgSrc,
}: TestimonialDataType) {
  return (
    <div
      className={
        id === 1 ? "carousel-item h-100 active" : "carousel-item h-100"
      }
      data-bs-interval="3000"
    >
      <div className="d-flex justify-content-center">
        <div className="card " style={{ maxWidth: "840px" }}>
          <div className="row g-0 align-items-center">
            <div className="col-md-12">
              <div className="card-body">
                <p className="lh-md mb-0 text-center">{testimonialText}</p>
              </div>
            </div>
            <div className="col-md-12 d-md-flex justify-content-center mb-2 align-items-center">
              <div className="d-md-block d-flex justify-content-center">
                <h5 className="text-center">
                  {personName} <br /> ({`${personCompany} - ${personTitle}`})
                </h5>
              </div>
              <div className="d-md-block d-flex justify-content-center">
                <img src={imgSrc} className="rounded TestimonailsLogo" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCarousel;
