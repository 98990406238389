import { RefObject, useRef } from "react";
import PricingApp from "./pricing-subcomponents/PricingApp";
import PricingDigital from "./pricing-subcomponents/PricingDigital";
import PricingGraphic from "./pricing-subcomponents/PricingGraphic";
import PricingPageHome from "./pricing-subcomponents/PricingPageHome";
import PricingWeb from "./pricing-subcomponents/PricingWeb";
import {Helmet} from "react-helmet";

function Pricing() {
  const webPricingDivRef = useRef<HTMLDivElement>(null);
  const digitalPricingDivRef = useRef<HTMLDivElement>(null);
  const graphicPricingDivRef = useRef<HTMLDivElement>(null);
  const appPricingDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Vyomscode Pricing | Affordable Rates for Web & App Development</title>
          <meta
            name="description"
            content="Discover our transparent and competitive pricing for custom web and mobile app development. Get a tailored quote for your project in Delhi, Mumbai, Varanasi, Lucknow, and other cities."
          />
          <meta 
          name="keywords" 
          content="website banane ka kharcha, app development cost, website banwane ka paisa, affordable software pricing, budget website banwaye, app development rates, cost of digital services, low-cost development, website kaise banwaye, kitna paisa lagega, custom web development charges, app development ka charge, budget-friendly web dev, pocket-friendly app dev, cost-effective software services, value for money, competitive pricing, web development pricing, app development cost, custom software pricing, Delhi web development rates, Mumbai app dev quotes, Varanasi development services pricing, Lucknow digital solutions cost" />
      </Helmet>
      <PricingPageHome
        webPricingDivRef={webPricingDivRef}
        digitalPricingDivRef={digitalPricingDivRef}
        graphicPricingDivRef={graphicPricingDivRef}
        appPricingDivRef={appPricingDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="webPricingDiv" ref={webPricingDivRef}>
        <PricingWeb />
      </div>

      <div id="digitalPricingDiv" ref={digitalPricingDivRef}>
        <PricingDigital />
      </div>

      <div id="graphicPricingDiv" ref={graphicPricingDivRef}>
        <PricingGraphic />
      </div>

      <div id="appPricingDiv" ref={appPricingDivRef}>
        <PricingApp />
      </div>
    </div>
  );
}

export default Pricing;
