import { profileData } from "../../../common/data";
import AboutUsProfileLargeDevice from "../reusable-components/AboutUsProfileLargeDevice";
import AboutUsProfileSmallDevice from "../reusable-components/AboutUsProfileSmallDevice"; 

function MpAboutUs() {
  return (
    <section id="about-us ">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 py-4">
            <h2 className="text-center text-light">
              <b>OUR TEAM MEMBERS</b>
            </h2>
          </div>
        </div>
        <div className="row small-hide justify-content-center">
          {profileData.map((item) => {
            return (
              <AboutUsProfileLargeDevice
                key={item.id}
                name={item.name}
                profileImg={item.profileImg}
                whatsappLink={item.whatsappLink}
                linkedinLink={item.linkedinLink}
                instagramLink={item.instagramLink}
                portfolioLink={item.portfolioLink}
                aboutMeText={item.aboutMeText}
              />
            );
          })}
        </div>
        <div className="row large-hide">
          <div className="col-12">
            <div
              id="carouselExampleInterval2"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner ">
                {profileData.map((item) => {
                  return (
                    <AboutUsProfileSmallDevice
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      profileImg={item.profileImg}
                      whatsappLink={item.whatsappLink}
                      linkedinLink={item.linkedinLink}
                      instagramLink={item.instagramLink}
                      portfolioLink={item.portfolioLink}
                      aboutMeText={item.aboutMeText}
                    />
                  );
                })}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleInterval2"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleInterval2"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MpAboutUs;
