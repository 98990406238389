import { RefObject, useEffect, useState } from "react";

function MpPageHome({
  servicesDivRef,
  workDivRef,
  aboutUsDivRef,
  scrollHandler,
}: {
  servicesDivRef: RefObject<HTMLDivElement>;
  workDivRef: RefObject<HTMLDivElement>;
  aboutUsDivRef: RefObject<HTMLDivElement>;
  scrollHandler: (elementRef: RefObject<HTMLDivElement>) => void;
}) {
  const words = [
    "WEBSITE",
    "APPLICATIONS",
    "LOGOS",
    "SOCIAL MEDIA POST",
    "SEO",
    "DIGITAL MARKETING",
  ];

  const [showWord, setShowWord] = useState("");

  function typeWriter(n: number = 0) {
    if (n >= words.length) {
      n = 0; 
    }
    let currentWord = words[n];
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < currentWord.length) {
        setShowWord(currentWord.substring(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          eraseWord(n);
        }, 500);
      }
    }, 100);
  }

  function eraseWord(n: number) {
    const currentWord = words[n];
    let i = currentWord.length - 1;
    const erasingInterval = setInterval(() => {
      if (i >= 0) {
        setShowWord(currentWord.substring(0, i));
        i--;
      } else {
        clearInterval(erasingInterval);
        setTimeout(() => typeWriter(n + 1), 500);
      }
    }, 100);
  }

  useEffect(() => {
    typeWriter();

    // eslint-disable-next-line
  }, []);

  return (
    <section id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 col-sm-12 pb-2 pt-3 pt-md-5 d-flex justify-content-center  mt-md-2">
            <div className="card-rotate-1 ">
              <h1 className="lh-md text-center text-light">
                <strong>
                  Stand Out With VyomsCode <br className="break" />
                  Your One-Stop Destination For <br />
                  {/* <!-- unique and creative --> */}
                  <span className="text-change" id="typing">
                    {showWord}
                  </span>
                  {/* <!-- WEBSITE APPLICATIONS LOGOS SOCIAL MEDIA POST SEO DIGITAL MARKETING --> */}
                </strong>
              </h1>
              <p className="text-light text-center">
                Unlock Your Digital Potential
              </p>
              <div className="d-flex justify-content-center">
                <span
                  className="nav-link"
                  onClick={() => {
                    scrollHandler(servicesDivRef);
                  }}
                >
                  <button className="btn btn-outline-light  mx-1">
                    Services
                  </button>
                </span>
                <span
                  className="nav-link"
                  onClick={() => {
                    scrollHandler(workDivRef);
                  }}
                >
                  <button className="btn btn-outline-light  mx-1">
                    &nbsp;&nbsp;Work&nbsp;&nbsp;
                  </button>
                </span>
                <span
                  className="nav-link"
                  onClick={() => {
                    scrollHandler(aboutUsDivRef);
                  }}
                >
                  <button className="btn btn-outline-light  mx-1">
                    About us
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MpPageHome;
