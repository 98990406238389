import { RefObject, useRef } from "react";
// import GoToTopButton from "../../common-components/GoToTopButton";
import DmAboutDigital from "./digital-marketing-subcomponents/DmAboutDigital";
import DmPageHome from "./digital-marketing-subcomponents/DmPageHome";
import {Helmet} from "react-helmet";

function DigitalMarketing() {
  const aboutDigitalDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Vyomscode Digital Marketing | SEO, PPC & Social Media Experts</title>
          <meta
            name="description"
            content="Grow your online reach with Vyomscode's digital marketing. We offer SEO, social media marketing, and content creation to drive your business growth"
          />
          <meta 
          name="keywords" 
          content="digital marketing India, SEO kaise kare, social media marketing kaise kare, PPC kya hota hai, content marketing tips, online presence kaise badhaye, Vyomscode digital marketing, business ke liye SEO, social media pe marketing kaise kare, content banane ke tips, lead generation kaise ho, digital marketing experts, social media marketing, search engine optimization, pay-per-click ads, content creation, lead generation,end-to-end services, digital marketing services, SEO, PPC, social media marketing, content marketing, Delhi digital agency, Mumbai digital marketing company, Varanasi digital solutions, Lucknow online marketing" />
      </Helmet>
      <DmPageHome aboutDigitalDivRef={aboutDigitalDivRef} scrollHandler={scrollHandler} />

      <div id="aboutDigitalDiv" ref={aboutDigitalDivRef}>
        <DmAboutDigital />
      </div>
    </div>
  );
}

export default DigitalMarketing;
